import React, { useEffect } from "react";
import Nav from "../../components/Nav";
import { Link } from "react-router-dom";
import "./index.css";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <div className="main_div mx-auto terms-and-conditions p-4 sm:p-8">
        <h1 className="text-3xl font-bold uppercase text-center mb-8">
          PRIVACY POLICY
        </h1>
        <p className="text-justify mb-5">
          This privacy policy applies between you, the User of this Website and
          Inside the Pocket Limited, the owner and provider of this Website.
          Inside the Pocket Limited takes the privacy of your information very
          seriously. This privacy policy applies to our use of any and all Data
          collected by us or provided by you in relation to your use of the
          Website.
        </p>
        <p className="text-justify mb-5">
          This privacy policy should be read alongside, and in addition to, our
          Terms and Conditions, which can be found at:
          <span className="font-black">
            {" "}
            <Link to="/terms-and-conditions">
              https://insidethepocket.biz/terms-and-conditions.{" "}
            </Link>
          </span>
        </p>
        <p className="font-black">Please read this privacy policy carefully.</p>
        <h2 className="font-bold text-xl my-8">
          Definitions and interpretation
        </h2>
        <p>
          <span className="mr-5">1.</span>In this privacy policy, the following
          definitions are used:
        </p>
        <table className="mt-5 text-center">
          <tbody>
            <tr>
              <td>Data</td>
              <td>
                Collectively all information that you submit to via the Website.
                This Inside the Pocket Limiteddefinition incorporates, where
                applicable, the definitions provided in the Data Protection
                Laws;
              </td>
            </tr>
            <tr>
              <td>Cookies</td>

              <td>
                A small text file placed on your computer by this Website when
                you visit certain parts of the Website and /or when you use
                certain features of the Website. Details of the cookies used by
                this Website are set out in the clause below ( Cookies );
              </td>
            </tr>
            <tr>
              <td>Data Protection Laws</td>
              <td>
                Any applicable law relating to the processing of personal Data,
                including but not limited to the Directive 96/46/EC (Data
                Protection Directive) or the GDPR, and any national implementing
                laws, regulations and secondary legislation, for as long as the
                GDPR is effective in the UK;
              </td>
            </tr>
            <tr>
              <td>DGDPR</td>
              <td>The General Data Protection Regulation (EU) 2016/679;</td>
            </tr>
            <tr>
              <td>Inside the Pocket Limited ,we or us;</td>
              <td>
                Inside the Pocket Limited , a company incorporated in England
                and Wales with registered number 12607772 whose registered
                office is at PO Box 410 , 235 Earls Court Road , London , SW5
                9FE;
              </td>
            </tr>
            <tr>
              <td>UK and EU Cookie Law</td>
              <td>
                the Privacy and Electronic Communications (EC Directive)
                Regulations 2003 as amended by the Privacy and Electronic
                Communications (EC Directive) (Amendment) Regulations 2011;
              </td>
            </tr>
            <tr>
              <td>User or you</td>
              <td>
                any third party that accesses the Website and is not either (i)
                employed by Inside the Pocket Limited and acting in the course
                of their employment or (ii) engaged as a consultant or otherwise
                providing services to Inside the Pocket Limited and accessing
                the Website in connection with the provision of such services;
                and
              </td>
            </tr>
            <tr>
              <td>Website</td>
              <td>
                the website that you are currently using,
                https://insidethepocket.biz , and any sub-domains of this site
                unless expressly excluded by their own terms and conditions.
              </td>
            </tr>
          </tbody>
        </table>

        <p className="mt-8 mb-5">
          <span className="mr-5">2.</span>In this privacy policy, unless the
          context requires a different interpretation:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>the singular includes the plural and
          vice versa
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>references to sub-clauses, clauses,
          schedules or appendices are to sub-clauses, clauses, schedules or
          appendices of this privacy policy
        </p>
        <p className="ml-8">
          <span className="mr-5">c.</span>a reference to a person includes
          firms, companies, government entities, trusts and partnerships
        </p>
        <p className="ml-8">
          <span className="mr-5">d.</span>&rdquo;including&rdquo; is understood
          to mean &rdquo;including without limitation&rdquo;
        </p>
        <p className="ml-8">
          <span className="mr-5">e.</span>reference to any statutory provision
          includes any modification or amendment of it
        </p>
        <p className="ml-8">
          <span className="mr-5">f.</span>the headings and sub-headings do not
          form part of this privacy policy.
        </p>

        <h2 className="font-bold text-xl my-8">Scope of this privacy policy</h2>

        <p className="my-5">
          <span className="mr-5">3.</span>This privacy policy applies only to
          the actions of Inside the Pocket Limited and Users with respect to
          this Website. It does not extend to any websites that can be accessed
          from this Website including, but not limited to, any links we may
          provide to social media websites.
        </p>

        <p className="my-5">
          <span className="mr-5">4.</span>For purposes of the applicable Data
          Protection Laws, Inside the Pocket Limited is the &rdquo;data
          controller&rdquo;. This means that Inside the Pocket Limited
          determines the purposes for which, and the manner in which, your Data
          is processed.
        </p>

        <h2 className="font-bold text-xl my-5">Data collected</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">5.</span>We may collect the following Data,
          which includes personal Data, from you:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>IP address (automatically collected)
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>Web browser type and version
          (automatically collected)
        </p>
        <p className="ml-8">
          <span className="mr-5">c.</span>Operating system (automatically
          collected)
        </p>
        <p className="mt-8 mb-5">
          in each case, in accordance with this privacy policy.
        </p>

        <h2 className="font-bold text-xl my-5">How we collect Data</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">6.</span>We collect Data in the following ways:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>Data is given to us by you; and
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>Data is collected automatically.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">
          Data that is given to us by you
        </h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">7.</span>Inside the Pocket Limited will collect
          your Data in a number of ways, for example:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>When you contact us through the
          Website, by telephone, post, e-mail or through any other means
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>When you use our services
        </p>
        <p className="mt-8 mb-5">
          in each case, in accordance with this privacy policy
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">
          Data that is collected automatically
        </h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">8.</span>To the extent that you access the
          Website, we will collect your Data automatically, for example:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>we automatically collect some
          information about your visit to the Website. This information helps us
          to make improvements to Website content and navigation, and includes
          your IP address, the date, times and frequency with which you access
          the Website and the way you use and interact with its content.
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>we will collect your Data
          automatically via cookies, in line with the cookie settings on your
          browser. For more information about cookies, and how we use them on
          the Website, see the section below, headed &rdquo;Cookies&rdquo;.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">Our use of Data</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">9.</span>Any or all of the above Data may be
          required by us from time to time in order to provide you with the best
          possible service and experience when using our Website. Specifically,
          Data may be used by us for the following reasons:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>improvement of our products / services
        </p>
        <p className="mt-8 mb-5">
          in each case, in accordance with this privacy policy.
        </p>
        <p className="mb-3">
          <span className="mr-5">10.</span>We may use your Data for the above
          purposes if we deem it necessary to do so for our legitimate
          interests. If you are not satisfied with this, you have the right to
          object in certain circumstances (see the section headed &rdquo;Your
          rights&rdquo;below):
        </p>
        <h2 className="font-bold text-xl mt-8 mb-1">Keeping Data secure</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">11.</span>We will use technical and
          organisational measures to safeguard your Data, for example:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>Access to your account is controlled
          by a password and a user name that is unique to you.
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>We store your Data on secure servers
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">12.</span>Technical and organisational measures
          include measures to deal with any suspected data breach. If you
          suspect any misuse or loss or unauthorised access to your Data, please
          let us know immediately by contacting us via this e-mail address:
          hello@insidethepocket.biz .
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">13.</span>If you want detailed information from
          Get Safe Online on how to protect your information and your computers
          and devices against fraud, identity theft, viruses and many other
          online problems, please visit www.getsafeonline.org. Get Safe Online
          is supported by HM Government and leading businesses.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">Data retention</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">14.</span>Unless a longer retention period is
          required or permitted by law, we will only hold your Data on our
          systems for the period necessary to fulfil the purposes outlined in
          this privacy policy or until you request that the Data be deleted.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">15.</span>Even if we delete your Data, it may
          persist on backup or archival media for legal, tax or regulatory
          purposes.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">Your rights</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">16.</span>You have the following rights in
          relation to your Data:
        </p>
        <p className="ml-8">
          <span className="mr-5">a.</span>
          <span className="font-black">Right to access</span> - the right to
          request copies of the information we hold about you at any time, or
          that we modify, update or delete such information. If we provide you
          with access to the information we hold about you, we will not charge
          you for this, unless your request is &quot;manifestly unfounded or
          excessive.&quot; Where we are legally permitted to do so, we may
          refuse your request. If we refuse your request, we will tell you the
          reasons why.
        </p>
        <p className="ml-8">
          <span className="mr-5">b.</span>
          <span className="font-black">Right to correct</span> - the right to
          have your Data rectified if it is inaccurate or incomplete.
        </p>
        <p className="ml-8">
          <span className="mr-5">c.</span>
          <span className="font-black">Right to erase </span>- the right to
          request that we delete or remove your Data from our systems.
        </p>
        <p className="ml-8">
          <span className="mr-5">d.</span>
          <span className="font-black">Right to restrict</span> our use of your
          Data - the right to &quot;block&quot; us from using your Data or limit
          the way in which we can use it.
        </p>
        <p className="ml-8">
          <span className="mr-5">e.</span>
          <span className="font-black">Right to data portability</span> - the
          right to request that we move, copy or transfer your Data.
        </p>
        <p className="ml-8">
          <span className="mr-5">f.</span>
          <span className="font-black">Right to object</span> - the right to
          object to our use of your Data including where we use it for our
          legitimate interests.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">17.</span>To make enquiries, exercise any of
          your rights set out above, or withdraw your consent to the processing
          of your Data (where consent is our legal basis for processing your
          Data), please contact us via this e-mail address:
          hello@insidethepocket.biz .
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">18.</span>If you are not satisfied with the way
          a complaint you make in relation to your Data is handled by us, you
          may be able to refer your complaint to the relevant data protection
          authority. For the UK, this is the Information Commissioner&rdquo;s
          Office (ICO). The ICO&rdquo;s contact details can be found on their
          website at{" "}
          <a
            href="https://ico.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://ico.org.uk/.
          </a>
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">19.</span>It is important that the Data we hold
          about you is accurate and current. Please keep us informed if your
          Data changes during the period for which we hold it.
        </p>
        <h2 className="font-bold text-xl mt-8 mb-1">Links to other websites</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">20.</span>This Website may, from time to time,
          provide links to other websites. We have no control over such websites
          and are not responsible for the content of these websites. This
          privacy policy does not extend to your use of such websites. You are
          advised to read the privacy policy or statement of other websites
          prior to using them.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">
          Changes of business ownership and control
        </h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">21.</span>Inside the Pocket Limited may, from
          time to time, expand or reduce our business and this may involve the
          sale and/or the transfer of control of all or part of Inside the
          Pocket Limited . Data provided by Users will, where it is relevant to
          any part of our business so transferred, be transferred along with
          that part and the new owner or newly controlling party will, under the
          terms of this privacy policy, be permitted to use the Data for the
          purposes for which it was originally supplied to us.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">22.</span>We may also disclose Data to a
          prospective purchaser of our business or any part of it.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">23.</span>In the above instances, we will take
          steps with the aim of ensuring your privacy is protected.
        </p>
        <div id="cookies">
          <h2 className="font-bold text-xl mt-8 mb-1">Cookies</h2>
        </div>
        <p className="mt-8 mb-5">
          <span className="mr-5">24.</span>This Website may place and access
          certain Cookies on your computer. Inside the Pocket Limited uses
          Cookies to improve your experience of using the Website and to improve
          our range of services. Inside the Pocket Limited has carefully chosen
          these Cookies and has taken steps to ensure that your privacy is
          protected and respected at all times.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">25.</span>All Cookies used by this Website are
          used in accordance with current UK and EU Cookie Law.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">26.</span>Before the Website places Cookies on
          your computer, you will be presented with a message bar requesting
          your consent to set those Cookies. By giving your consent to the
          placing of Cookies, you are enabling Inside the Pocket Limited to
          provide a better experience and service to you. You may, if you wish,
          deny consent to the placing of Cookies; however certain features of
          the Website may not function fully or as intended.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">27.</span>This Website may place the following
          Cookies:
        </p>

        <table className="mt-5 text-justify">
          <thead>
            <tr>
              <td className="text-lg text-center">Type of Cookie</td>
              <td className="text-lg text-center">Purpose</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Analytical/performance cookies</td>
              <td>
                They allow us to recognise and count the number of visitors and
                to see how visitors move around our website when they are using
                it. This helps us to improve the way our website works, for
                example, by ensuring that users are finding what they are
                looking for easily.
              </td>
            </tr>
          </tbody>
        </table>

        <p className="mt-8 mb-5">
          <span className="mr-5">28.</span>You can find a list of Cookies that
          we use in the Cookies Schedule.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">29.</span>You can choose to enable or disable
          Cookies in your internet browser. By default, most internet browsers
          accept Cookies but this can be changed. For further details, please
          consult the help menu in your internet browser.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">30.</span>You can choose to delete Cookies at
          any time; however you may lose any information that enables you to
          access the Website more quickly and efficiently including, but not
          limited to, personalisation settings.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">31.</span>It is recommended that you ensure
          that your internet browser is up-to-date and that you consult the help
          and guidance provided by the developer of your internet browser if you
          are unsure about adjusting your privacy settings.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">32.</span>For more information generally on
          cookies, including how to disable them, please refer to
          aboutcookies.org. You will also find details on how to delete cookies
          from your computer.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">General</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">33.</span>You may not transfer any of your
          rights under this privacy policy to any other person. We may transfer
          our rights under this privacy policy where we reasonably believe your
          rights will not be affected.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">34.</span>If any court or competent authority
          finds that any provision of this privacy policy (or part of any
          provision) is invalid, illegal or unenforceable, that provision or
          part-provision will, to the extent required, be deemed to be deleted,
          and the validity and enforceability of the other provisions of this
          privacy policy will not be affected.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">35.</span>Unless otherwise agreed, no delay,
          act or omission by a party in exercising any right or remedy will be
          deemed a waiver of that, or any other, right or remedy.
        </p>

        <p className="mt-8 mb-5">
          <span className="mr-5">36.</span>This Agreement will be governed by
          and interpreted according to the law of England and Wales . All
          disputes arising under the Agreement will be subject to the exclusive
          jurisdiction of the English and Welsh courts.
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">
          Changes to this privacy policy
        </h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">37.</span>Inside the Pocket Limited reserves
          the right to change this privacy policy as we may deem necessary from
          time to time or as may be required by law. Any changes will be
          immediately posted on the Website and you are deemed to have accepted
          the terms of the privacy policy on your first use of the Website
          following the alterations.
        </p>

        <p className="mt-8 mb-5">
          You may contact Inside the Pocket Limited by email at
          hello@insidethepocket.biz .
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">Attribution</h2>

        <p className="mt-8 mb-5">
          <span className="mr-5">38.</span>This privacy policy was created using
          a document from Rocket Lawyer (https://www.rocketlawyer.com/gb/en). 01
          December 2020
        </p>

        <h2 className="font-bold text-xl mt-8 mb-1">Cookies</h2>

        <p className="mt-8 mb-5">
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </p>
        <p className="mt-8 mb-5">
          Analytical/performance <br /> We use the following
          analytical/performance cookies:
        </p>

        <table className="mt-5 text-justify mb-10">
          <thead>
            <tr>
              <td className="text-lg text-center">Description of Cookie</td>
              <td className="text-lg text-center">Purpose</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_cflb</td>
              <td>
                Manage network resources, manage traffic and protect sites from
                malicious traffic
              </td>
            </tr>
            <tr>
              <td>_cf_bm</td>
              <td>
                Manage network resources, manage traffic and protect sites from
                malicious traffic
              </td>
            </tr>
            <tr>
              <td>_cfduid</td>
              <td>
                Manage network resources, manage traffic and protect sites from
                malicious traffic
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
