import React from "react";
import "./index.css";

export default function Header({ onClickButton }) {
  const type = "meetus";
  const classNameButton = ` bg-gradient-to-l from-top via-mid to-bottom 
    rounded-full 
    p-2 lg:p-3
    lg:m-2 
    text-white
    cursor-pointer
    flex flex-col 
    justify-center
    items-center
  `;

  return (
    <>
      <div className="bg-white mx-auto md:h-40" id="header-bg">
        <div className="p-2 md:h-40 flex items-center">
          <span
            onClick={() => onClickButton({ type })}
            className={classNameButton}
            aria-hidden
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
}
