import React, { useState } from "react";
import Card from "../../Card";
import * as emailjs from "emailjs-com";
import { CHECK } from "../../../svgs";

export default function Form({ type }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject] = useState("Event Enquiry");
  const [submited, setSubmited] = useState(false);
  const [isFull, setIsFull] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();

    let templateParams = {
      email_to_receive: "hussain@insidethepocket.biz",
      /* email_to_receive: 'rafaelr@prettytechnical.io', */
      email: email,
      to_name: "Hussain",
      name: name,
      message: message,
      subject: `${subject}: ${type}`,
    };

    emailjs.send(
      "service_pt",
      "template_3main1l",
      templateParams,
      "user_TapqIT1c5loVunkiDh1HA"
    );

    if (name && email && message && subject) {
      setEmail("");
      setMessage("");
      setName("");
      setIsFull(true);
      setSubmited(true);
    } else {
      setIsFull(false);
    }
  }

  if (submited)
    return (
      <Card
        className="flex flex-row sm:flex-col form-confirmation
                        justify-between items-center sm:justify-center
                        bg-gradient-to-l from-top via-mid to-bottom
                        p-8 sm:mb-16 md:mb-24"
      >
        <img alt="check" className="check-max-w mb-0 sm:mb-4" src={CHECK} />
        <span className="text-lg font-bold">Message received</span>
      </Card>
    );
  return (
    <div className="footer-form flex flex-col justify-start w-1/3 text-black">
      <form className="flex flex-col" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          placeholder="NAME"
          value={name}
          className="mt-4 p-2 font-bold"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="EMAIL"
          value={email}
          className="mt-4 p-2 font-bold"
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          type="text"
          placeholder="MESSAGE"
          value={message}
          className="mt-4 p-2 font-bold form-message"
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="flex flex-col justify-center items-center">
          <button
            type="submit"
            className="mt-2 uppercase text-white rounded font-medium tracking-wider p-4 bg-white"
          >
            Submit
          </button>
        </div>
      </form>
      {!isFull && (
        <span className="mx-auto text-base font-bold">
          Please fill all the fields
        </span>
      )}
    </div>
  );
}
