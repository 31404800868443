import React from "react";
import Diagram from "./Diagram";
import "./index.css";

export default function Benefits() {
  const info = [
    {
      id: "1.",
      shortTitle: "Maximum flexibility",
      title: "Maximum flexibility for optimum engagement",
      content:
        "You know your customers best, so we keep you in control. All aspects of our platform are modular and fully flexible, meaning you can select whichever games fit your customers’ needs, with empirical user data to de-risk your choices.",
    },
    {
      id: "2.",
      shortTitle: "Optimum engagement",
      title: "Optimum engagement for stronger relationships",
      content:
        "Giving your customers the gaming experience they want gives you the chance to glean deeper insights into their behaviour – all of which can be fed back into your CRM and customer communications to further enhance relationships.",
    },
    {
      id: "3.",
      shortTitle: "Stronger relationships",
      title: "Stronger relationships for new opportunities",
      content:
        "The foundations of engagement and loyalty give you the leverage to convert customer relationships beyond free-to-play and into real-money accounts, with active, paying customers transitioning to regulated gaming environments.",
    },
  ];

  return (
    <div id="benefits" className="poligon_left">
      <div className="flex flex-col justify-center items-center  p-4 items-center pt-16  z-0 ">
        <h1 className="titleDesc mb-2">Helping raise your game</h1>
        <span className="subtitleDesc lg:w-2/4 md:w-3/4 sm:px-4 -mb-6">
          Sporting moments are defined by the thrill of the win, but they are
          rooted in a love of the game. With Inside the Pocket on your side, you
          can attract customers and keep them coming back for more with a new,
          mobile-driven experience that goes big on in-game excitement and
          post-play rewards.
        </span>
      </div>
      <div className="main_div">
        <Diagram info={info} />
      </div>
    </div>
  );
}
