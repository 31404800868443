import React, { useState } from "react";
import Card from "../../../components/Card";
import * as emailjs from "emailjs-com";
import { CHECK } from "../../../svgs";
import "./index.css";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("Work with us");
  const [submited, setSubmited] = useState(false);
  const [isFull, setIsFull] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();

    let templateParams = {
      email: email,
      to_name: "hello@insidethepocket.biz",
      name: name,
      message: message,
      subject,
    };

    emailjs.send(
      "contact_service",
      "template_kwxcuf8",
      templateParams,
      "user_UWBjWm7LUT8TaPMc4VuCs"
    );

    if (name && email && message && subject) {
      setEmail("");
      setMessage("");
      setName("");
      setIsFull(true);
      setSubmited(true);
    } else {
      setIsFull(false);
    }
  }

  if (submited) {
    setTimeout(() => {
      setSubmited(false);
      window.scrollTo(0, 0);
    }, 4000);
    clearTimeout();

    return (
      <Card
        className="flex flex-row sm:flex-col form-confirmation
                        justify-between items-center sm:justify-center
                        bg-gradient-to-l from-top via-mid to-bottom
                        p-8 sm:mb-16 md:mb-24"
      >
        <img alt="check" className="check-max-w mb-0 sm:mb-4" src={CHECK} />
        <span className="text-lg font-bold text-white">Message received</span>
      </Card>
    );
  }
  return (
    <div className="footer-form flex flex-col ">
      <div className="flex flex-row text-base items-baseline justify-center items-center">
        <p
          className={`cursor-pointer text-xs sm:text-base ${
            subject === "Ask us" ? "font-bold text-white" : ""
          }`}
          onClick={() => setSubject("Ask us")}
          aria-hidden="true"
        >
          Ask us
        </p>
        <span className="mx-2 sm:mx-4">/</span>
        <p
          className={`cursor-pointer text-xs sm:text-base ${
            subject === "Partner with us" ? "font-bold text-white" : ""
          }`}
          onClick={() => setSubject("Partner with us")}
          aria-hidden="true"
        >
          Partner with us
        </p>
        <span className="mx-2 sm:mx-4">/</span>
        <p
          className={`cursor-pointer text-xs sm:text-base ${
            subject === "Work with us" ? "font-bold text-white" : ""
          }`}
          onClick={() => setSubject("Work with us")}
          aria-hidden="true"
        >
          Work with us
        </p>
        <p className="uppercase ml-8 text-xs font-medium">(select one)</p>
      </div>
      <form className="flex flex-col" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          placeholder="NAME"
          value={name}
          className="mt-4 p-2 font-bold"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="EMAIL"
          value={email}
          className="mt-4 p-2 font-bold"
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          type="text"
          placeholder="MESSAGE"
          value={message}
          className="mt-4 p-2 font-bold form-message"
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="flex flex-row text-base items-baseline justify-center items-center">
          <button type="submit" className="Form_button mb-2 p-2 mt-4 ">
            <span className=" Form_button-text">Submit</span>
          </button>
        </div>
      </form>
      {!isFull && (
        <span className="mx-auto text-base font-bold text-red-600">
          Please fill all the fields
        </span>
      )}
    </div>
  );
}
