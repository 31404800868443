import React from "react";

export default function GrandSection({ img, optionalText, title }) {
  return (
    <>
      <div className={`bg-white h-auto mx-auto flex flex-col gap-8 ${optionalText && 'mb-10'}`}>
        {img && <img src={img} alt="img-header" className="object-fill w-full" />}
        {title && <p className="text-base md:text-xl lg:text-4xl font-bold justify-center uppercase text-center text-dark-cornflower-blue">
          {title}
        </p>}        
      </div>
      {optionalText && (
          <div className="text-dark-cornflower-blue p-4 flex flex-col gap-8 mx-auto mb-12 md:mb-32 max_width lg:w-7/12">
            <ul className="flex flex-col gap-5 list-disc px-4 lg:px-0">
              {optionalText?.map((text, idx) => (
                <li key={`text-${idx + 1}`}>
                  <p className="text-base md:text-xl lg:text-4x font-light justify-center text-justify">
                    {text}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
    </>
  );
}
