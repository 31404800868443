/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";

import "./index.css";

export default function Diagram({ info }) {
  const [windowSize, setWindowSize] = useState(window.screen.width);

  const onResize = () => {
    setWindowSize(window.screen.width);
  };

  window.onresize = onResize;
  console.log(windowSize);
  return (
    // <div className="benefits-wrapper flex relative p-3">
    <div className="flex  flex-col lg:flex-row mt-6 mb-6 ">
      {info.map((el) => {
        return (
          <div key={el.id} className="flex flex-row items-center ">
            <div className="flex flex-col">
              <h3 className="diagram-id mt-6">{el.id}</h3>
              <h3 className="diagram-title mt-6">{el.shortTitle}</h3>
              <p className="diagram-description p-4"> {el.content}</p>
            </div>
            {/* {el.id !== "3." ? <div className="diagram-line"></div> : null} */}
          </div>
        );
      })}
    </div>
  );
}
