/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import Header from "../Header"
import GradSection from "../GradSection"
import { PhonesWithBackground } from "../Phones"
import Benefits from '../MeetusBenefits'
import Footer from '../FooterG2E'
import Main from "../MainTextG2E"
import Arrows from "../../images/benefits/down-arrows.png"
import GradSectionImg from "../../images/grand-section/grad-section-developer.png"
import Baseball from '../../images/baseball.png';
import "./index.css";

export default function Developer({ onClickButton }) {
  const type = "dev"

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header onClickButton={onClickButton}/>
      <GradSection img={GradSectionImg} />
      <Main type={type}/>
      <PhonesWithBackground />
      <div className="h-0 flex flex-col items-center">
        <img src={Arrows} alt="arrows" className="m-0 relative top-3 md:top-2 lg:top-3 w-24 md:w-auto"/>
      </div>
      <Benefits type={type}/>
      <div className="relative -top-3 md:-top-10 h-0 m-0 -left-3 md:-left-10 w-1/2 md:w-full">
        <img src={Baseball} alt="baseball" />
      </div>
      <Footer className="max_width" onClickButton={onClickButton} type="Developer"/>
    </div>
  );
}
