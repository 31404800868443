import React from "react";
import Card from "../../Card";
import "./index.css";

export default function AboutCard({ src, title, content, className }) {
  return (
    <Card
      className={`p-8 sm:p-8 about-card w-full flex flex-col md:flex-row items-center`}
    >
      <Card className="about-image__bg relative bg-gradient-to-bl from-top via-mid to-bottom">
        <img
          src={src}
          alt="about-img"
          className="about-image rounded-xl h-14 "
        />
        <h3
          className={`absolute
                                ${className}
                                font-bold text-3xl md:text-xl 2xl:text-2xl 
                                leading-10 md:leading-5 2xl:leading-10
                                text-white uppercase text-center`}
        >
          {title}
        </h3>
      </Card>
      <p
        className="about-subtitle font-normal
                          text-sm leading-5 text-center md:text-left mt-4 md:mt-0 md:ml-4"
      >
        {content}
      </p>
    </Card>
  );
}
