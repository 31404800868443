export const textDeveloper = [
  {
    title: `UNRIVALLED DISTRIBUTION AND SCALE`,
    content: `Our B2B clients want choice but hate making choices. They want new customer leads, demographic and psychographic data, and an unparalleled understanding of who their customers really are. Make it easy to get your F2P content in the mix, with our single integration approach.`,
  },
  {
    title: `WE MAKE THE HARD CHOICES EASY`,
    content: `Offer global markets any free or freemium sports betting game type in any jurisdiction and grow your current player and revenue base...prove that your games are the best!`,
  },
  {
    title: `MAKE LIFE EASIER`,
    content: `Your content will be exposed to new audiences in the fastest and broadest way available. You will be able to see individual, game and (for the first time) network data across all games and game types on the platform.`,
  },
  {
    title: `REAL TIME FEEDBACK AND MONITORING`,
    content: `Once live, our operational teams use state of the art monitoring tools and work hand-in-hand with our game partners to ensure maximum uptime and revenue for the operators.`,
  },
  {
    title: `EMBRACE THE PARADIGM SHIFT`,
    content: `The market loves us because they no longer need to make binary choices among game providers... we give them access to everything. And that means giving your products exposure to those clients – who may not have found them organically`,
  },
  {
    title: `REACH NEW AUDIENCES`,
    content: `If your games are great, it will be no surprise that they rise to the top of client content picks – over your competitors – ensuring you never lose a deal.`,
  },
];
export const textOperator = [
  {
    content: `We are the only platform aggregating free-to-play and pay-to-play content to any market in the world. Integrate any F2P and third-party content, in under a month – no limitations`,
  },
  {
    content: `Our back-office drives performance with data-driven and actionable insight – what games, content and campaigns perform best for different audience segments`,
  },
  {
    content: `As well as our own suite of out-of-the-box games and third party integrated content, we can design and build bespoke games for you – wholly owned or shared IP as well as geo-restricted exclusivity`,
  },
];
