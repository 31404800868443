import React, { useEffect } from "react";
import Nav from "../../components/Nav";

import Features from "../../components/Features";
import Benefits from "../../components/Benefits";
import About from "../../components/About";

import Footer from "../../components/Footer";
import Hero from "../../components/Hero";

import "./index.css";

import Overview from "../../components/Overview";
import Partner from "../../components/Partner";
import HeroDescription from "../../components/Hero/HeroDescription";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <Hero />
      <HeroDescription />
      <Overview />
      <Features />
      <Benefits />
      <About />
      <Partner />
      <Footer />
    </div>
  );
}
