import merge from 'lodash/merge'
import isURL from 'validator/lib/isURL'
import { foxRssNewArticlesApiCall } from '../lib/axios'
   

function prefixCmsHostToImgUrl(img) {
    if (!img) return null

    return merge({}, img, {
        url: isURL(img.url) ? undefined : ``,
    })
}
 
export async function getDashboard() {
    const [
        
        rssNewArticles,
    ] = await Promise.all([
         
        foxRssNewArticlesApiCall(),
    ])
  
    return merge({ 
        rssNewArticles: [...rssNewArticles].map((rssNewArticle) =>
            merge({}, rssNewArticle, {
                img: prefixCmsHostToImgUrl(rssNewArticle.img),
                icon: prefixCmsHostToImgUrl(rssNewArticle.icon),
            }),
        ),
    })
}
