import React from "react";
import Card from "../Card";
import { ICON_MOBILES, ICON_BESPOKE, ICON_DATA, ICON_COGS } from "../../svgs";
import "./index.css";

export default function Features({ className }) {
  return (
    <div
      id="features"
      className={`${className} 3xl:mx-auto m-4 lg:m-8 flex flex-col main_div`}
    >
      <div
        className="flex flex-col lg:flex-row 
                            my-8
                            text-center text-md lg:text-sm lg:text-xl text-white font-bold"
      >
        <Card
          className="features-card
                                 py-8 px-8 lg:flex"
        >
          <div className="features-card__content-before flex flex-row lg:flex-col items-center justify-between lg:justify-center">
            <img alt="mobiles" src={ICON_MOBILES} />
            <h4
              className="mt-0 lg:mt-4 
                                        xl:mx-8 2xl:mx-16 uppercase"
            >
              Game Development
            </h4>
          </div>
          <div
            className="features-card__content-after hidden 
                                    flex-col justify-between 
                                    lg:justify-start items-start"
          >
            <h4 className="uppercase">Game Development</h4>
            <p className="mt-0 lg:mt-2 text-sm font-normal text-left">
              Our Games Studio is home to specialist game developers with an eye
              for creating native and custom games that sports fans love. Our
              strong track record is matched by a continually evolving pipeline
              of exciting new ways to play.
            </p>
          </div>
        </Card>
        <Card
          className="features-card
                                 py-8 px-8 lg:flex"
        >
          <div className="features-card__content-before flex flex-row lg:flex-col items-center justify-between lg:justify-center">
            <img alt="bespoke" src={ICON_BESPOKE} />
            <h4
              className="mt-0 lg:mt-4 
                                        xl:mx-8 2xl:mx-16 uppercase"
            >
              Open Aggregation
            </h4>
          </div>
          <div
            className="features-card__content-after hidden 
                                    flex-col justify-between 
                                    lg:justify-start items-start"
          >
            <h4 className="uppercase">Open Aggregation</h4>
            <p className="mt-0 lg:mt-2 text-sm font-normal text-left">
              We are unique in welcoming all games developers onto our platform.
              It’s part of our philosophy of democratising gaming and ensuring
              you and your players get access to the broadest mix of the best
              games available.
            </p>
          </div>
        </Card>
        <Card
          className="features-card
                                 py-8 px-8 lg:flex"
        >
          <div className="features-card__content-before flex flex-row lg:flex-col items-center justify-between lg:justify-center">
            <img alt="cogs" src={ICON_COGS} />
            <h4
              className="mt-0 lg:mt-4 
                                        xl:mx-8 2xl:mx-16 uppercase"
            >
              Player Management
            </h4>
          </div>
          <div
            className="features-card__content-after hidden 
                                    flex-col justify-between 
                                    lg:justify-start items-start"
          >
            <h4 className="uppercase">Player Management</h4>
            <p className="mt-0 lg:mt-2 text-sm font-normal text-left">
              From APIs for front-end and CRM connectivity to KYC and secure
              wallets, we provide seamless integration with your systems. This
              ensures you can quickly verify, onboard and support players on
              their gaming journey.
            </p>
          </div>
        </Card>
        <Card
          className="features-card
                                 py-8 px-8 lg:flex"
        >
          <div className="features-card__content-before flex flex-row lg:flex-col items-center justify-between lg:justify-center">
            <img alt="data" src={ICON_DATA} />
            <h4
              className="mt-0 lg:mt-4 
                                        xl:mx-8 2xl:mx-16 uppercase"
            >
              Multi-level data
            </h4>
          </div>
          <div
            className="features-card__content-after hidden 
                                    flex-col justify-between 
                                    lg:justify-start items-start"
          >
            <h4 className="uppercase">Multi-level data</h4>
            <p className="mt-0 lg:mt-2 text-sm font-normal text-left">
              Our dashboards give you the full picture on player activity. This
              starts with individual user journeys and in-play data but includes
              an additional wealth of shared insight on player segments from
              across the full breadth of our gaming network.
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}
