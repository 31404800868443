export const mainTextOperator = `THE SINGLE PLATFORM INTEGRATION THAT OPENS AN ENTIRE FREE-TO-PLAY CONTENT ECOSYTEM FOR CUSTOMER ACQUISITION, RETENTION AND MONETISATION`;
export const textOperator = [
  `Everyone needs a way to efficiently attract and retain customers, lower acquisition costs, drive lifetime value and convert users to real-money activity – like betting, pay-to-play gaming, loyalty programmes and subscriptions.`,
  `Through a single integration, you can open up the entire free-to-play marketplace featuring six of the top global content providers and curate dynamic, tailored content across mobile, web and on-site. And You will finally know exactly what games, content and marketing campaigns drive the most audience engagement and conversions.`,
];

export const mainTextDeveloper = `QUICKLY AND EFFICIENTLY GET YOUR CONTENT IN FRONT OF A GLOBAL MARKET AND ACCESS OUR DISTRIBUTION NETWORK`;
export const textDeveloper = [
  `Operators have long and complex runways with little time to change their roadmaps. They have regulatory hurdles to cross at every turn... limiting opportunities to get your games out to market.`,
  `Our ground-breaking free-to-play and pay-to-play sports gaming and content ecosystem puts your product centre stage with operators, media companies, teams, leagues, and brands – quickly, at scale and anywhere in the world.`,
];
