import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { ITP_LOGO } from "../../svgs";

export default function Nav({ className }) {
  return (
    <div className="">
      <div
        className={`${className} main_div  lg:pl-16 md:pl-8 sm:pl-8 h-32 p-4 sm:p-0 sm:h-16 2xl:h-22  flex  3xl:mx-auto sm:flex-row flex-col justify-items-center items-center bg-white`}
      >
        <Link to="/">
          <img alt="logo" src={ITP_LOGO} className=" logo-max-w" />
        </Link>
        <nav className="nav-bar my-auto flex items-center flex-wrap justify-center sm:mr-12 text-xs 2xl:text-base sm:ml-auto sm:mx-0 mx-auto">
          <a href="/#overview">Overview</a>
          <span>/</span>
          <a href="/#features">Key Features</a>
          <span>/</span>
          <a href="/#benefits">Benefits</a>
          <span>/</span>
          <a href="/News">News</a>
          <span>/</span>
          <a href="/#about">About us</a>
          <span>/</span>
          <a href="/#partner">Partners</a>
          <span>/</span>
          <a href="/#contact">Contact</a>
          <a
            href="https://insidethepocket.io"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-2xl ml-4 tracking-wide"
          >
            PLATFORM
          </a>
        </nav>
      </div>
    </div>
  );
}
