import React  from 'react'
import format from 'date-fns/format'

// eslint-disable-next-line no-unused-vars
export default function ArticleCard({ data, articleSelection }) {

    return (
         
                <div
                    className={` w-auto md:max-w-xss  flex flex-row md:flex-col flex-wrap  font-bold gap-4`}
                    aria-hidden 
                >
                    <div className=" flex flex-col mb-4">
                         
                            <img
                                alt={data.title}
                                className="object-fill"
                                src={data.img.url}
                            />
                        
                        <div className="justify-start py-2  bg-light-blue">
                             <p className="px-2 text-sm font-normal m-0">{format(new Date(data.pubDate), 'd MMMM yyyy')}</p> 
                             <p className="px-2 text-sm line-clamp-2">{data.title}</p> 
                        <a 
                            href='/News#article'
                            onClick={() => articleSelection( data, false)}
                            className="text-xs text-center py-1 absolute bg-news text-white mb-2 italic border rounded-full w-32 font-bold "
                            >
                                { 'Read more' }
                        </a>
                        </div>
                     
                    </div>
                     
                </div> 
        
    )
}
 