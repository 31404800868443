import React from 'react'
import Home from './views/Home'
import TermsAndConditions from './views/TermsAndConditions'
import PrivacyPolicy from './views/PrivacyPolicy'
import News from './views/News'

import Meetus from './views/Meetus'
import Developer from './views/Developer'
import Operator from './views/Operator'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" name="Home" component={Home}/>
        <Route exact path="/meetus" name="Meetus" component={Meetus}/>
        <Route exact path="/developer" name="Developer" component={Developer}/>
        <Route exact path="/operator" name="Operator" component={Operator}/>
        <Route exact path="/terms-and-conditions" name="TermsAndConditions" component={TermsAndConditions}/>
        <Route exact path="/privacy-policy" name="PrivacyPolicy" component={PrivacyPolicy}/>
        <Route exact path="/news" name="News" component={News}/> 
      </Switch>
    </Router>
  )
}