import React, { useState, useEffect } from "react";

export default function HeroDescription() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const listener = () => {
    setScreenWidth(window.innerWidth);
  };
  console.log(screenWidth);
  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <div className="">
      <div className="poligon">
        <div className="flex flex-col justify-center p-4 items-center pt-16 pb-10 z-0 -mt-10 md:-mt-12">
          <h1 className="titleDesc mb-2">
            Open to all players, <br></br> open for all games
          </h1>
          <span className="subtitleDesc lg:w-2/4 md:w-3/4 sm:px-4">
            Inside The Pocket is your end-to-end partner for sports gaming on
            mobile. By harnessing the power of free-to-play, our white-label
            platform enables you to create a dynamic, tailored gaming experience
            that builds brand loyalty and opens exciting new market
            opportunities.
          </span>
        </div>
      </div>
    </div>
  );
}
