import DASHBOARD_BANNER_IMG_1_1 from './hero-header-1/hero-header-1.png'
import DASHBOARD_BANNER_IMG_1_2 from './hero-header-1/hero-header-1@2x.png'
import DASHBOARD_BANNER_IMG_1_3 from './hero-header-1/hero-header-1@3x.png'
import DASHBOARD_BANNER_IMG_2_1 from './hero-header-2/hero-header-2.png'
import DASHBOARD_BANNER_IMG_2_2 from './hero-header-2/hero-header-2@2x.png'
import DASHBOARD_BANNER_IMG_2_3 from './hero-header-2/hero-header-2@3x.png'
import DASHBOARD_BANNER_IMG_3_1 from './hero-header-3/hero-header-3.png'
import DASHBOARD_BANNER_IMG_3_2 from './hero-header-3/hero-header-3@2x.png'
import DASHBOARD_BANNER_IMG_3_3 from './hero-header-3/hero-header-3@3x.png'

const BANNER_1_1 = { src: DASHBOARD_BANNER_IMG_1_1, w: 720}
const BANNER_1_2 = { src: DASHBOARD_BANNER_IMG_1_2, w: 1440}
const BANNER_1_3 = { src: DASHBOARD_BANNER_IMG_1_3, w: 2160}
const BANNER_2_1 = { src: DASHBOARD_BANNER_IMG_2_1, w: 720}
const BANNER_2_2 = { src: DASHBOARD_BANNER_IMG_2_2, w: 1440}
const BANNER_2_3 = { src: DASHBOARD_BANNER_IMG_2_3, w: 2160}
const BANNER_3_1 = { src: DASHBOARD_BANNER_IMG_3_1, w: 720}
const BANNER_3_2 = { src: DASHBOARD_BANNER_IMG_3_2, w: 1440}
const BANNER_3_3 = { src: DASHBOARD_BANNER_IMG_3_3, w: 2160}

export { 
    BANNER_1_1, 
    BANNER_1_2,
    BANNER_1_3,
    BANNER_2_1,
    BANNER_2_2, 
    BANNER_2_3,
    BANNER_3_1, 
    BANNER_3_2,
    BANNER_3_3,
}
export { default as MISSION } from './ball.jpeg'
export { default as STRENTH } from './woman.jpeg'
export { default as BG_1 } from './background1_1.png'
export { default as BG_3 } from './background1_2.png'
export { default as BG_2 } from './background2_1.png'
export { default as BG_4 } from './background2_2.png'
export { default as SBC_LOGO } from './main-bg/SBC-logo.png'
export { default as ICE_LOGO } from './main-bg/ICE-London-Logo.png'
export { default as BG_MEETUS_LOGO } from './main-bg/shutterstock.png'
export { default as GROUP_LOGO } from './main-bg/Group-phone-sports.png'


export { default as PHONES } from './phones/phones-reflections.png'
export { default as PHONES_BG } from './phones/phones-developer.png'
