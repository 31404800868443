/* eslint-disable no-underscore-dangle */
import convert from "xml-js";
// import _toLower from "lodash/toLower";
import { foxNewsClient } from "../src/config/Axios";

function rssImagify(url) {
  if (!url) return null;
  return { url, width: 335, height: 220 };
}

function rssLinkify(rawTitle) {
  return (
    // Removes line cuts, line breaks and tabs from the url
    rawTitle.replace(/\r\n+|\r+|\n+|\t+/gi, "")
  );
}

export async function foxRssNewArticlesApiCall() {
    
    
    const { data: rawXML } = await foxNewsClient.get('/sports.xml')

  const data = convert.xml2js(rawXML, { compact: true });
  const rawItems = data.rss.channel.item;

  const items = rawItems.map(
    ({
      title: { _text: header },
      description: { _cdata: content },
      "media:group": {
        "media:content": [
          {
            _attributes: { url },
          },
        ],
      },
      link: { _text: article },
      pubDate: { _text: date },
    }) => {
      return {
        url: rssLinkify(article),
        title: header,
        description: content,
        img: rssImagify(url),
        action: "Read more",
        pubDate: date,
      };
    }
  );

  return items;
}
