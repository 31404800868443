import React, { useEffect, useState } from "react";
import Nav from "../../components/Nav";
import format from "date-fns/format";
import "./index.css";
import ArticleCard from "./ArticleCard";
import Footer from "./Footer";

import { getDashboard } from ".../../../lib/graphql";

export default function News() {
  const [article, setIArticle] = useState({});
  const [articlesList, setIArticlesList] = useState([]);
  const [showArticle, setIShowArticle] = useState(true);

  const makeAPICall = async () => {
    try {
      const [{ rssNewArticles }] = await Promise.all([getDashboard()]);

      setIArticlesList(rssNewArticles);
    } catch (e) {
      console.log(e);
    }
  };
  const articleSelection = (data, status) => {
    setIShowArticle(status);
    setIArticle(data);
  };

  useEffect(() => {
    makeAPICall();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(article);
  return (
    <div className="flex flex-col content-between min-h-new">
      <Nav />

      {articlesList.length ? (
        showArticle ? (
          <>
            <div
              id="article"
              className=" bg-gradient-to-l from-top via-mid to-bottom  banner-news "
            >
              <div className="max-w-full banner-news bg-news-bg  from-transparent bg-middle flex flex-col items-center justify-center text-white p-4 sm:p-8">
                <p className="text-3xl font-medium uppercase text-center ">
                  News
                </p>
                <p className="  font-normal uppercase text-center  text-lg mb-8">
                  All the latest news and updates from the World of ITP
                </p>
              </div>
            </div>
            <div className="flex justify-end px-4 b-4 md:px-12 -mt-12 font-bold  italic">
              <span className="text-white text-2xl md:text-4xl blend-new">
                SET THE PACE
              </span>
              <span className="text-white text-opacity-25 absolute -mb-1 md:-mr-10 text-2xl md:text-4xl blend-new">
                SET THE PACE
              </span>
              <span className="text-white text-opacity-25 absolute -mt-5 md:-mt-10 md:-mr-6 text-4xl md:text-6xl blend-new">
                SET THE PACE
              </span>
            </div>
            <div className="text-news px-4 md:px-32 pb-16 mx-auto main_div ">
              <div className="flex-col md:flex-row items-center justify-start flex">
                <div className="">
                  <span className="text-base self-center border border-white font-bold ">
                    {format(new Date(articlesList[0].pubDate), "d MMMM yyyy")}
                  </span>

                  <img
                    src={articlesList[0].img.url}
                    alt={articlesList[0].title}
                    className="h-full w-full md:max-w-img-news shadow-2xl object-fill "
                  />
                </div>
                <div className="flex flex-col justify-stretch  px-3 md:px-6">
                  <p className="text-2xl max-w-lg my-3 md:my-4  self-start font-bold">
                    {articlesList[0].title}
                  </p>
                  <span className="border border-t-1 border-mid bg-mid "></span>
                  <p className="text-sm max-w-xl my-3 md:my-4 self-start ">
                    {articlesList[0].description}
                  </p>
                  <button
                    type="button"
                    onClick={() => articleSelection(articlesList[0], false)}
                    className="text-xs py-1 bg-news text-white mb-2 italic border rounded-full w-32 font-bold "
                  >
                    {"Read more"}
                  </button>
                </div>
              </div>

              <div className=" flex justify-between my-4 pt-5 md:pt-10 ">
                <span className="text-md md:text-2xl font-bold">
                  Latest Stories
                </span>
              </div>

              <div className="flex  mx-full flex-wrap gap-4 scrollbar-hidden justify-center md:justify-start">
                {articlesList.map((article, index) =>
                  index ? (
                    <div
                      key={`handPickedCard-${index + 1}`}
                      className="flex w-auto my-1 justify-center"
                    >
                      <ArticleCard
                        data={article}
                        articleSelection={articleSelection}
                      />
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="article" className="flex flex-wrap   ">
              <div className="flex flex-col bg-gradient-to-l w-full from-top via-mid to-bottom  md:w-9/12 ">
                <div className="max-w-full px-3  md:px-32 from-transparent  flex flex-col items-start justify-center text-white p-4 sm:px-8">
                  <p className="font-normal italic text-center 3xl:w-full text-sm">
                    {"Home > News > News story"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full bg-light-blue md:w-3/12">
                <div className="max-w-full "></div>
              </div>
            </div>
            <div className="flex flex-wrap  text-news ">
              <div className="flex flex-col mt-0 ml-auto max-w-news justify-stretch mb-8 px-3 md:px-20  md:w-8/12">
                <img
                  src={article.img.url}
                  alt={article.title}
                  className="md:h-20 w-full  -mt-3 md:w-auto object-fill"
                />
                <span className="text-sm self-start border border-white font-normal mt-3">
                  {format(new Date(article.pubDate), "d MMMM yyyy")}
                </span>
                <p className="text-2xl max-w-lg my-3 md:my-4  self-start font-bold">
                  {article.title}
                </p>
                <span className="border border-t-1 border-mid bg-mid "></span>
                <p className="text-sm max-w-xl my-3 md:my-4 self-start ">
                  {article.description}
                </p>
                <div className=" flex justify-between  ">
                  <a
                    onClick={() => articleSelection({}, true)}
                    href="/News#article"
                    className="text-xs py-1 my-2  w-24 text-news cursor-pointer  italic font-bold"
                  >
                    {"< Back to news"}
                  </a>
                  <a
                    href={article.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-xs py-1 bg-news text-center text-white my-2 italic border rounded-full w-40 font-bold "
                  >
                    {"Read more on Fox News"}
                  </a>
                </div>
              </div>

              <div className="flex flex-col px-3 md:-mt-13  md:px-10 bg-light-blue md:w-4/12 ">
                <p className="text-2xl max-w-lg mb-3 md:mb-4 mt-3 md:mt-5 self-start font-bold">
                  {"More News"}
                </p>
                {articlesList.map((article, index) =>
                  index && index < 4 ? (
                    <>
                      <p className=" text-sm max-w-lg my-2 self-start font-bold">
                        {format(new Date(article.pubDate), "d MMMM yyyy")}
                      </p>
                      <div className="flex flex-col pb-2 md:max-w-xss">
                        <img
                          src={article.img.url}
                          alt={article.title}
                          className="md:h-40 w-auto md:w-64 object-fill"
                        />
                        <p className=" text-sm text-white m-2 -mt-16 line-clamp-2 w-auto self-start font-bold">
                          {article.title}
                        </p>
                      </div>
                      <a
                        href="/News#article"
                        onClick={() => articleSelection(article, false)}
                        className="text-xs py-1 text-center bg-news text-white my-2 italic border rounded-full w-32 font-bold "
                      >
                        {"Read more"}
                      </a>
                      <span className="border border-t-1 md:w-64 border-news bg-news mt-2"></span>
                    </>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </>
        )
      ) : (
        ""
      )}
      <div className="bg-gradient-to-l from-top mt-auto via-mid to-bottom">
        <Footer />
      </div>
    </div>
  );
}
