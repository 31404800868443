import React from 'react'
import { PHONES, PHONES_BG } from '../../images'

export default function Phones() {
  return (
    <div className='w-full'>
      <img src={PHONES} alt="phones" className='w-full mx-auto'/>
    </div>
  )
}

export function PhonesWithBackground() {
  return (
    <div className='w-full mb-12'>
      <img src={PHONES_BG} alt="phones" className='w-full mx-auto'/>
    </div>
  )
}