import React, { useState, useEffect } from "react";
import "./index.css";

export default function Hero() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const listener = () => {
    setScreenWidth(window.innerWidth);
  };
  console.log(screenWidth);
  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <div className="hero_bg">
      <div className="flex flex-row sm:items-center h-full  main_div">
        <div className="sm:pl-16 flex w-full flex-col">
          <div className="sm:h-full sm:w-full">
            <div className="w-full text-center sm:text-left">
              <h1 className="title  mt-16 sm:mt-0 mb-2 text-center sm:text-left">
                Harnessing the power <br></br>of free-to-play
              </h1>
              <span className="subtitle mb-2 text-center sm:text-left">
                Access to the latest games, bets and technology.<br></br> All
                inside your pocket.<br></br>
              </span>{" "}
              <a href="/#features">
                <button className="Hero_button mt-2">
                  <span className="sm:text-xl">Find out More</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
