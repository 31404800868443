import React from "react";
import AboutCard from "./Card";
import { MISSION, STRENTH } from "../../images";
import "./index.css";

export default function About() {
  return (
    <div id="about" className="main_div  justify-center items-center">
      <div className="flex flex-col justify-center p-4 items-center pt-8 pb-8 z-0">
        <h1 className="titleDesc mb-2">Ahead of the field</h1>
        <span className="subtitleDesc lg:w-2/4 md:w-3/4 sm:px-4 -mb-6">
          At Inside the Pocket, we are pioneering the aggregator model in
          free-to-play gaming.
        </span>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <AboutCard
          src={MISSION}
          title="Mission"
          content="Our mission is to help sports leagues and teams,
                             media organisations and betting operators build 
                             audience engagement on the basis of openness and 
                             choice – and without the need for multiple costly integrations."
          className="mission-card"
        />
        <AboutCard
          src={STRENTH}
          title="Strength in technology"
          content="Our strength in technology is matched by our
                             understanding of market dynamics in the free-to-play 
                             gaming space. The combination of the two means we are 
                             perfectly positioned to develop solutions that open 
                             new doors to audience engagement and growth."
          className="strength-card"
        />
      </div>
    </div>
  );
}
