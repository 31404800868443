import React, { useEffect } from "react";
import Nav from "../../components/Nav";
import "./index.css";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <div className="main_div  mx-auto terms-and-conditions space-y-3 termns-n-conditions p-4 sm:p-8">
        <h1 className="text-3xl font-bold uppercase text-center">
          Terms and conditions of use
        </h1>
        <h2 className="text-2xl font-bold">Introduction</h2>

        <p>
          These terms and conditions apply between you, the User of this Website
          (including any sub-domains, unless expressly excluded by their own
          terms and conditions), and Inside the Pocket Limited, the owner and
          operator of this Website. Please read these terms and conditions
          carefully, as they affect your legal rights. Your agreement to comply
          with and be bound by these terms and conditions is deemed to occur
          upon your first use of the Website. If you do not agree to be bound by
          these terms and conditions, you should stop using the Website
          immediately.
        </p>

        <p>
          In these terms and conditions, <strong>User</strong> or{" "}
          <strong> Users</strong> means any third party that accesses the
          Website and is not either employed by Inside the Pocket Limited and
          acting in the course of their employment or engaged as a consultant or
          otherwise providing services to Inside the Pocket Limited and
          accessing the Website in connection with the provision of such
          services.
        </p>

        <p>
          You must be at least 18 years of age to use this Website. By using the
          Website and agreeing to these terms and conditions, you represent and
          warrant that you are at least 18 years of age.
        </p>

        <h2 className="text-2xl font-bold">
          Intellectual property and acceptable use
        </h2>
        <ol className="pl-8 termns-n-conditions__main-counter-start termns-n-conditions__main-counter space-y-3">
          <li>
            All Content included on the Website, unless uploaded by Users, is
            the property of Inside the Pocket Limited, our affiliates or other
            relevant third parties. In these terms and conditions, Content means
            any text, graphics, images, audio, video, software, data
            compilations, page layout, underlying code and software and any
            other form of information capable of being stored in a computer that
            appears on or forms part of this Website, including any such content
            uploaded by Users. By continuing to use the Website you acknowledge
            that such Content is protected by copyright, trademarks, database
            rights and other intellectual property rights. Nothing on this site
            shall be construed as granting, by implication, estoppel, or
            otherwise, any license or right to use any trademark, logo or
            service mark displayed on the site without the owner&apos;s prior
            written permission
          </li>

          <li className="termns-n-conditions__main-counter">
            <p>
              You may, for your own personal, non-commercial use only, do the
              following:
            </p>

            <ol className="pl-8 space-y-3 list-lower-alpha">
              <li>
                retrieve, display and view the Content on a computer screen
              </li>
            </ol>
          </li>

          <li>
            You must not otherwise reproduce, modify, copy, distribute or use
            for commercial purposes any Content without the written permission
            of Inside the Pocket Limited.
          </li>
        </ol>

        <h2 className="text-2xl font-bold">Prohibited use</h2>

        <ol className="space-y-3 termns-n-conditions__main-counter pl-8">
          <li>
            <span>
              You may not use the Website for any of the following purposes:
            </span>

            <ol className="pl-8 space-y-3">
              <li>
                a. in any way which causes, or may cause, damage to the Website
                or interferes with any other person&apos;s use or enjoyment of
                the Website;
              </li>
              <li>
                b. in any way which is harmful, unlawful, illegal, abusive,
                harassing, threatening or otherwise objectionable or in breach
                of any applicable law, regulation, governmental order;
              </li>
              <li>
                c .making, transmitting or storing electronic copies of Content
                protected by copyright without the permission of the owner.
              </li>
            </ol>
          </li>
        </ol>

        <h2 className="text-2xl font-bold">Links to other websites</h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            This Website may contain links to other sites. Unless expressly
            stated, these sites are not under the control of Inside the Pocket
            Limited or that of our affiliates.
          </li>
          <li>
            We assume no responsibility for the content of such Websites and
            disclaim liability for any and all forms of loss or damage arising
            out of the use of them.
          </li>
          <li>
            The inclusion of a link to another site on this Website does not
            imply any endorsement of the sites themselves or of those in control
            of them.
          </li>
        </ol>

        <h2 className="text-2xl font-bold">
          Privacy Policy and Cookies Policy
        </h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            Use of the Website is also governed by our Privacy Policy and
            Cookies Policy, which are incorporated into these terms and
            conditions by this reference. To view the Privacy Policy and Cookies
            Policy, please click on the following:&nbsp;
            <a
              className="font-black"
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            &nbsp;and&nbsp;
            <a
              className="font-black"
              href="/privacy-policy/#cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
            .
          </li>
        </ol>
        <h2 className="text-2xl font-bold">
          Availability of the Website and disclaimers
        </h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            Any online facilities, tools, services or information that Inside
            the Pocket Limited makes available through the Website (the Service)
            is provided &quot;as is&quot; and on an &quot;as available&quot;
            basis. We give no warranty that the Service will be free of defects
            and/or faults. To the maximum extent permitted by the law, we
            provide no warranties (express or implied) of fitness for a
            particular purpose, accuracy of information, compatibility and
            satisfactory quality. Inside the Pocket Limited is under no
            obligation to update information on the Website.
          </li>
          <li>
            Whilst Inside the Pocket Limited uses reasonable endeavours to
            ensure that the Website is secure and free of errors, viruses and
            other malware, we give no warranty or guaranty in that regard and
            all Users take responsibility for their own security, that of their
            personal details and their computers.
          </li>
          <li>
            Inside the Pocket Limited accepts no liability for any disruption or
            non-availability of the Website.
          </li>
          <li>
            Inside the Pocket Limited reserves the right to alter, suspend or
            discontinue any part (or the whole of) the Website including, but
            not limited to, any products and/or services available. These terms
            and conditions shall continue to apply to any modified version of
            the Website unless it is expressly stated otherwise.
          </li>
        </ol>

        <h2 className="text-2xl font-bold">Limitation of liability</h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            Nothing in these terms and conditions will:<br></br> (a) Limit or
            exclude our or your liability for death or personal injury resulting
            from our or your negligence, as applicable.<br></br> (b) Limit or
            exclude our or your liability for fraud or fraudulent
            misrepresentation.<br></br> (c) Limit or exclude any of our or your
            liabilities in any way that is not permitted under applicable law.
          </li>
          <li>
            We will not be liable to you in respect of any losses arising out of
            events beyond our reasonable control.
          </li>
          <li>
            <span>
              To the maximum extent permitted by law, Inside the Pocket Limited
              accepts no liability for any of the following:
            </span>

            <ol className="pl-8 space-y-3">
              <li>
                a. any business losses, such as loss of profits, income,
                revenue, anticipated savings, business, contracts, goodwill or
                commercial opportunities;
              </li>
              <li>b. loss or corruption of any data, database or software;</li>
              <li>c. any special, indirect or consequential loss or damage.</li>
            </ol>
          </li>
        </ol>

        <h2 className="text-2xl font-bold">General</h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            You may not transfer any of your rights under these terms and
            conditions to any other person. We may transfer our rights under
            these terms and conditions where we reasonably believe your rights
            will not be affected.
          </li>
          <li>
            These terms and conditions may be varied by us from time to time.
            Such revised terms will apply to the Website from the date of
            publication. Users should check the terms and conditions regularly
            to ensure familiarity with the then current version.
          </li>
          <li>
            These terms and conditions together with the Privacy Policy and
            Cookies Policy contain the whole agreement between the parties
            relating to its subject matter and supersede all prior discussions,
            arrangements or agreements that might have taken place in relation
            to the terms and conditions.
          </li>
          <li>
            The Contracts (Rights of Third Parties) Act 1999 shall not apply to
            these terms and conditions and no third party will have any right to
            enforce or rely on any provision of these terms and conditions.
          </li>
          <li>
            If any court or competent authority finds that any provision of
            these terms and conditions (or part of any provision) is invalid,
            illegal or unenforceable, that provision or part-provision will, to
            the extent required, be deemed to be deleted, and the validity and
            enforceability of the other provisions of these terms and conditions
            will not be affected.
          </li>
          <li>
            Unless otherwise agreed, no delay, act or omission by a party in
            exercising any right or remedy will be deemed a waiver of that, or
            any other, right or remedy.
          </li>
          <li>
            This Agreement shall be governed by and interpreted according to the
            law of England and Wales and all disputes arising under the
            Agreement (including non-contractual disputes or claims) shall be
            subject to the exclusive jurisdiction of the English and Welsh
            courts.
          </li>
        </ol>

        <h2 className="text-2xl font-bold">
          Inside the Pocket Limited details
        </h2>

        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            Inside the Pocket Limited is a company incorporated in England and
            Wales with registered number 12607772 whose registered address is PO
            Box 410, 235 Earls Court Road, London, SW5 9FE and it operates the
            Website &nbsp;
            <a
              className="font-black"
              href="https://insidethepocket.biz"
              target="_blank"
              rel="noopener noreferrer"
            >
              insidethepocket.biz
            </a>
            .
            <br />
            You can contact Inside the Pocket Limited by email on &nbsp;
            <a
              className="font-black"
              href="mailto:hello@insidethepocket.biz"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@insidethepocket.biz
            </a>
            .
          </li>
        </ol>

        <h2 className="text-2xl font-bold">Attribution</h2>
        <ol className="termns-n-conditions__main-counter space-y-3 pl-8">
          <li>
            These terms and conditions were created using a document from&nbsp;
            <a
              className="font-black"
              href="https://www.rocketlawyer.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rocket Lawyer
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}
