import axios from "axios";
// baseURL: 'https://cors-anywhere.herokuapp.com/https://moxie.foxnews.com/feedburner',

export const foxNewsClient = axios.create({
  headers: {
    "Content-Type": "application/xml",
  },
});

export { axios };
