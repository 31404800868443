import React from "react";
import { Link } from "react-router-dom";
import { LOGO_WHITE } from "../../svgs";

export default function Footer() {
  return (
    <div>
      <div className="Footer_line"></div>
      <div className="bg-footer_blue">
        <div className="footer-bottom flex flex-col  text-center md:flex-row md:justify-between   p-6 main_div">
          <div className="flex flex-col items-center md:text-left md:items-start">
            <img
              src={LOGO_WHITE}
              alt="logo"
              className="mb-2 w-1/3 md:w-full md:text-left"
            />
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </div>

          <div className="flex flex-col items-end md:text-right">
            <p className="footer-rights md:items-end">
              <div className="flex flex-col items-center md:flex-row-reverse md:items-end">
                <a
                  href="https://www.linkedin.com/company/inside-the-pocket-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className=" md:-mb-2 md:ml-2"
                    src={require("../../../src/images/linkedin.png")}
                    alt=""
                  />
                </a>
                <p className="md:text-right">
                  {" "}
                  We are social. Contact us on{" "}
                  <a
                    href="https://www.linkedin.com/company/inside-the-pocket-limited/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn{" "}
                  </a>
                </p>
              </div>
              <br></br>
              Inside The Pocket. All rights reserved. Inside The Pocket Limited
              (company registration number 12607772). This site uses cookies,
              for more information please see our&nbsp;
              <Link to="/privacy-policy" className="font-black">
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
