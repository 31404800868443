import React, { useCallback, useState, useEffect  } from "react"
import { useHistory } from 'react-router'
import Main from "../../components/Main"
import "./index.css";

export default function Meetus() {
  const history = useHistory()
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
 
  const listener = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };
 console.log(screenWidth, screenHeight)
  useEffect(() => {
    window.addEventListener("resize", listener); 
    return () => window.removeEventListener("resize", listener);
  }, []);
  const selectedType = useCallback(({type}) => {
    history.push(`${type}`)
  }, [history]);

  return (
    <div>
      <Main onClickButton={selectedType} height={screenHeight} width={screenWidth} />
    </div>
  );
}
