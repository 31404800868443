import React from "react";
import { LOGO_WHITE } from "../../svgs";
import { Link } from "react-router-dom";
import Form from "./Form";
import "./index.css";

export default function Footer({ onClickButton, type: typeForm }) {
  const type = "/#overview";
  return (
    <div className="p-4 sm:p-8 text-white text-xs bg-dark-cornflower-blue">
      <div className="flex flex-col justify-center items-center gap-5">
        <Form type={typeForm} />
        <div className="h-1 w-1/2 bg-white rounded-md" />
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="rounded-full bg-gradient-to-l from-top via-mid to-bottom text-sm p-2"
            onClick={() => onClickButton({ type })}
            aria-hidden
          >
            Go to main website
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 text-xs">
          <img
            src={LOGO_WHITE}
            alt="logo"
            className="w-full my-8 md:col-span-1"
          />
          <div className="flex flex-col p-4 md:col-span-3">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-and-conditions">Terms and Conditions</a>
            Inside The Pocket. All rights reserved. Inside The Pocket Limited
            (company registration number 12607772).
            <span>
              This site uses cookies, for more information please see our&nbsp;
            </span>
            <Link to="/privacy-policy" className="font-black">
              Privacy Policy .
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
