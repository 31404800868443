import React, { useState, useEffect } from "react";
import "./index.css";

export default function Overview() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const listener = () => {
    setScreenWidth(window.innerWidth);
  };
  console.log(screenWidth);

  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <div id="overview">
      <div className="Overviewn_poligon flex flex-col justify-center items-center mt-16 mb-8">
        <h1 className="Overview_title mb-8">
          Create your own Gaming, <br></br> Casino Sports offer
        </h1>
        <div className="flex  flex-col md:flex-col lg:flex-row justify-around items-center flex-nowrap lg:ml-12">
          <div className="flex flex-row  flex-nowrap  py-4 px-4">
            <span className="Overview_subtitle mx-auto px-2">FREEMIUM</span>
            <span className="Overview_arrow  px-2">>></span>
          </div>
          <div className="flex flex-row  flex-nowrap  py-4 px-4">
            <span className="Overview_subtitle mx-auto px-2">FREE-TO-PLAY</span>
            <span className="Overview_arrow  px-2">>></span>
          </div>
          <div className="flex flex-row  flex-nowrap  py-4 px-4">
            <span className="Overview_subtitle mx-auto px-2">PAY-TO-PLAY</span>
            <span className="Overview_arrow  px-2">>></span>
          </div>
        </div>
        <img
          className={"mt-6"}
          src={require("../../images/redbar.png")}
          alt=""
        />

        <div className="flex flex-col justify-center p-4 items-center pt-16 pb-8 z-0 -mt-10 md:-mt-12">
          <h1 className="titleDesc mb-2">A match-ready gaming system</h1>
          <span className="subtitleDesc lg:w-2/4 md:w-3/4 sm:px-4 -mb-8">
            Inside The Pocket is a complete sports gaming ecosystem wrapped into
            an easy-to-integrate platform. It enhances your customers’ enjoyment
            of sport through a layer of play, while offering you a flexible,
            scalable route to new users and high-impact engagement based on
            real-time audience insight.
          </span>
        </div>
      </div>
    </div>
  );
}
