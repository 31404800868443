import React from "react"
import BlueLine from "../../images/grand-section/blue-text-line.png"
import { textDeveloper, textOperator, mainTextDeveloper, mainTextOperator } from "./texts";

export default function MainText({ type }) {
    const typesMainText = {
        op: mainTextOperator,
        dev: mainTextDeveloper
    }

    const typesText = {
        dev: textDeveloper,
        op: textOperator
    }

    return (
        <div
            className="text-dark-cornflower-blue p-4 flex flex-col gap-8 mx-auto"
            id="white-section"
        >
            <div className="max_width mx-auto grid grid-cols-7 gap-3 my-8">
                <p className="text-base md:text-xl lg:text-4xl font-normal justify-center uppercase col-start-2 col-span-5 text-center">
                    {typesMainText[type]}
                </p>
                <img src={BlueLine} alt="blue line" className="col-start-4" />
                <span className="text-base md:text-xl lg:text-4xl font-bold justify-center text-center text-dark-cornflower-blue col-span-7 uppercase">
                    Landscape
                </span>
                {typesText[type]?.map((text, idx) => (
                    <div key={`text-${idx+1}`} className="col-span-7 grid-cols-7 grid">
                        <p className="text-base md:text-xl lg:text-4x font-light justify-center col-start-2 col-span-5 text-center">
                            {text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
