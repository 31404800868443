import React from "react";
import "./index.css";

export default function Partner() {
  return (
    <div id="partner">
      <div className="  text-center">
        <div
          className="max-w-full bg-partner-bg  from-transparent bg-cover partner_poligon
         flex flex-col items-center justify-center text-white p-4 sm:p-8"
        >
          <div className="flex flex-col justify-center p-4 items-center pt-8 pb-8 z-0">
            <h1 className="partner_title mb-2 mt-8">A true team effort</h1>
            <span className="partner_subtitle  lg:w-2/4 md:w-3/4 sm:px-4 ">
              As a platform based on openness, we pride ourselves on building
              strong partnerships with best-in-class mobile game developers.
              These collaborative relationships expand our content offering
              beyond our own native gaming portfolio and provide our partners
              with exposure to a far broader network of end users.
            </span>
          </div>
          <a href="/#contact">
            <button className="partner_button mb-2 pb-2 pt-2 px-6 mt-4">
              <span className=" partner_button-text">Partner with us</span>
            </button>
          </a>
          <span className="partner_subtitle mb-2 pb-8 lg:w-2/4 mt-4">
            If you are interested in becoming a part of the Inside The Pocket
            team, <br></br> contact us for more information.
          </span>
        </div>
      </div>
    </div>
  );
}
