import React from "react";

export default function BackOffice() {
  return (
    <div className="text-dark-cornflower-blue p-4 flex flex-col gap-8 mx-auto ">
      <div className="max_width mx-auto flex flex-col gap-8 my-8 lg:w-2/3">
        <p className="text-base md:text-xl lg:text-4xl font-bold justify-center uppercase text-center">
          Back Office Management & Analytics
        </p>
        <ul className="flex flex-col gap-5 list-disc px-4 lg:px-0">
          <li className="text-base md:text-xl lg:text-4x font-light justify-center text-justify">
            The only platform to aggregate free-to-play and pay-to-play sports
            games and content – we deliver cross-network, per-game, and
            individual player data analytics.
          </li>
          <li className="text-base md:text-xl lg:text-4x font-light justify-center text-justify">
            Manage your acquisition cycle with deep insight into acquisition
            cost, incentives and conversion rates - track sign-ups and pay your
            traffic sources with affiliate management; give players all-access
            with F2P accounts that easily convert into real money accounts.
          </li>
          <li className="text-base md:text-xl lg:text-4x font-light justify-center text-justify">
            Automated emails and messaging through centralised campaign
            management (Salesforce and other CRM tools) - offer multiple
            wallets, multiple currencies and many integrated payment providers
            (from Activation).
          </li>
          <li className="text-base md:text-xl lg:text-4x font-light justify-center text-justify">
            Offer multiple wallets, multiple currencies and many integrated
            payment providers (from Activation).
          </li>
        </ul>
      </div>
    </div>
  );
}
