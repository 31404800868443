import React from "react";
import Carousel from "nuka-carousel";
import "./index.css";
import { textDeveloper, textOperator } from "./texts";

export default function Benefits({ type }) {
  const classNameContainerCard =
    "bg-white p-3 md:p-5 h-10 md:h-15 lg:h-20 flex flex-col justify-center items-center gap-1";
  const classnameContainerText =
    "text-xss md:text-lg lg:text-2xl font-medium text-center";

  const typesText = {
    dev: textDeveloper,
    op: textOperator,
  };

  return (
    <>
      <span className="text-base md:text-xl lg:text-4xl font-bold justify-center text-center text-dark-cornflower-blue flex flex-col uppercase mb-16">
        Benefits
      </span>
      <div
        className="p-10 md:p-16 text-dark-cornflower-blue flex flex-col justify-center items-center mx-auto h-full"
        id="benefits-section"
      >
        <Carousel
          className="max_width"
          renderBottomCenterControls={() => <></>}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide} className="relative -left-1.2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 md:h-8 md:w-8 bg-gray-600 text-white rounded-full"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} className="relative left-1.2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 md:h-8 md:w-8 bg-gray-600 text-white rounded-full"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        >
          {typesText[type]?.map((e, idx) => (
            <div key={`text-${idx + 1}`} className={classNameContainerCard}>
              {e?.title && (
                <h2 className="text-xs md:text-lg lg:text-3xl font-bold text-center uppercase">
                  {e.title}
                </h2>
              )}
              <p className={classnameContainerText}>{e.content}</p>
            </div>
          ))}
        </Carousel>
        <div className="max-w-3xl text-white p-5">
          <p className="text-xss md:text-base lg:text-2xl text-center uppercase font-bold">
            Get your games in front of a global audience - we strive to be a
            gold standard partner for content developers, and believe our
            approach yields multiple win-win benefits.
          </p>
        </div>
      </div>
    </>
  );
}
