import React, { useCallback } from "react"
import { useHistory } from 'react-router'
import Developer from "../../components/Developer"
import "./index.css";

export default function Meetus() {
  const history = useHistory()

  const selectedType = useCallback(({type}) => {
    history.push(`${type}`)
  }, [history]);

  return (
    <div>
      <Developer onClickButton={selectedType} />
    </div>
  );
}
