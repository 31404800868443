import React from "react";
import "./index.css";
import MainCard from "../MainCard";
import { GROUP_LOGO } from '../../images'

export default function Main({ onClickButton, height, width }) {
  
  return (
    
    <div className="flex justify-center" style={height < 657 ? {height: 700 } :{ height: height }}>
      
      { width < 768 ? '' : <div className="meetu-l flex z-10"></div> }
      <div className="bg-gradient-to-l meetu-t self-end z-10  from-top via-mid to-bottom " style={height < 657 ? {} :{ height: height }} >
        <div className="meetu-t   self-end   bga"  style={height < 657 ? {} :{ height: height }}></div>
      </div>
      { width < 768 ? '' : <div className="meetu-r flex self-end"></div> }
      
      <MainCard
        buttonMain="Operator"
        buttonSecondary="Content Provider"
        onClickButton={onClickButton}
      />
            { width < 768 ? '' : <div className=" z-10 mt-10 md:mt-20"> <img alt='logo' src={GROUP_LOGO} className='w-1/4  absolute position-img  md:m-auto' style={height < 657 ? {  } :{ height: height-200 }} /></div> }

    </div>
  );
}
