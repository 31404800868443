import React from "react";
import { ITP_LOGO } from "../../svgs";
import { ICE_LOGO } from "../../images";

export default function IntroCard({
  buttonMain,
  buttonSecondary,
  onClickButton,
}) {
  const classNameContainer = `  
      flex flex-col 
      items-center justify-center  self-center 
      text-dark-cornflower-blue 
      z-50
    `;
  const classNameContainerButtons = ` mt-2 flex flex-col items-center  gap-4`;

  const classNameButtons = ` text-2xl
      lg:text-4xl
      p-3
      lg:px-12
      w-full
      text-center
      cursor-pointer  
      bg-meetus-btn 
      text-white 
      rounded-full
      font-medium
      shadow-2xl
    
    `;

  return (
    <div className={classNameContainer}>
      <img alt="logo" src={ITP_LOGO} className="w-64 mt-5 md:m-auto" />
      <div className="bg-gradient-to-l clip mt-8 from-top via-mid to-bottom h-2 w-40">
        <div id="triangle" />
      </div>
      <img alt="logo" src={ICE_LOGO} className="w-64 md:m-auto" />
      <p className="text-xl text-meetus-ice ">12 - 14 APRIL 2022</p>
      <p className="text-3xl my-6 text-meetus-btn ">I’m an / a</p>
      <div className="flex flex-col md:grid md:grid-cols-2 gap-5  ">
        <div className={classNameContainerButtons}>
          <span
            onClick={() => onClickButton({ type: "operator" })}
            aria-hidden
            className={classNameButtons}
          >
            {buttonMain}
          </span>
        </div>
        <div className={classNameContainerButtons}>
          <span
            onClick={() => onClickButton({ type: "developer" })}
            aria-hidden
            className={classNameButtons}
          >
            {buttonSecondary}
          </span>
        </div>
      </div>
      <div className="text-white mt-8 text-center">
        <p>© 2022 Inside The Pocket. All rights reserved</p>
      </div>
    </div>
  );
}
