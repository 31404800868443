import React, { useEffect } from "react";
import Header from "../Header";
import GradSection from "../GradSection";
import Phones from "../Phones";
import Benefits from "../MeetusBenefits";
import Footer from "../FooterG2E";
import Main from "../MainTextG2E";
import BackOffice from "../BackOfficeSection";
import Arrows from "../../images/benefits/down-arrows.png";
import GradSectionImg from "../../images/grand-section/grad-section-operator.png";
import Baseball from "../../images/baseball.png";
import "./index.css";

export default function Operator({ onClickButton }) {
  const type = "op";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header onClickButton={onClickButton} />
      <GradSection img={GradSectionImg} />
      <Main type={type} />
      <div className="h-0 flex flex-col items-center">
        <img
          src={Arrows}
          alt="arrows"
          className="m-0 relative top-3 md:top-3 w-24 md:w-auto"
        />
      </div>
      <Benefits type={type} />
      <BackOffice type={type} />
      <Phones />
      <div className="relative -top-3 md:-top-10 h-0 m-0 -left-3 md:-left-10 w-1/2 md:w-full">
        <img src={Baseball} alt="baseball" />
      </div>
      <Footer
        className="max_width"
        onClickButton={onClickButton}
        type="Developer"
      />
    </div>
  );
}
